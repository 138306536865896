import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input } from 'semantic-ui-react'
import InputMask from 'react-input-mask'

const PhoneNumber = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
    warningText
}) => {
    const { t } = useTranslation()

    const pastHandler = e => {
        const pastedValue = e.clipboardData.getData('Text').trim()
        const phoneNumber = normalizePhoneNumber(pastedValue);

        if(phoneNumber.length == 12) {
            e.preventDefault()
            onChange(e, { value: pastedValue, name })
            onChange(e, { value: phoneNumber, name })
        } 
    }

    const normalizePhoneNumber = value => {
        let processedValue = '';
        
        // Remove all non-digit characters first
        const digitsOnly = value.replace(/\D/g, '');
        
        // Check if the number starts with 48 (with or without + prefix)
        if (value.startsWith('+48') || value.startsWith('48')) {
            // Add the +48 prefix and the rest of the digits
            processedValue = `+48${digitsOnly.substring(digitsOnly.startsWith('48') ? 2 : 0)}`;
        }
        
        return processedValue;
    }

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                    {warningText && <span className='label-warning-text'> ({t(warningText)})</span>}
                </label>
            ) : null}
            <InputMask
                mask="+48999999999"
                maskChar={null}
                value={value || ''}
                disabled={isDisabled}
                onChange={e => onChange(e, { value: e.target.value, name })}
                onPaste={pastHandler}
            >
                {inputProps => (
                    <Input
                        {...inputProps}
                        placeholder={placeholder}
                        list={valuesList && name}
                        className={className}
                        type={type}
                        disabled={isDisabled}
                        name={name}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                        error={error}
                        required={isRequired}
                    />
                )}
            </InputMask>
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name} />
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    )
}

export default PhoneNumber
